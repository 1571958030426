import {Adsense} from '@ctrl/react-adsense';
import {HAY_ADSENSE,ADSENSE_CA,ADSENSE_BANN_TECLADO} from '../../constants/strings'

export function BannerAdsense() {
  
if (HAY_ADSENSE) {
  return (
    <div className="flex justify-center footer publisobreteclado">
    <Adsense
    client={ADSENSE_CA}
    slot={ADSENSE_BANN_TECLADO}
    style={{display:'inline-block',width:'99%',height:'60px'}}
    format=''
  />
  </div> );
} else {
  return (null);
}
}
