import { WORDS } from '../constants/wordlist'
import { VALIDGUESSES } from '../constants/validGuesses'
import { WRONG_SPOT_MESSAGE, NOT_CONTAINED_MESSAGE } from '../constants/strings'
import { getGuessStatuses } from './statuses'
import { default as GraphemeSplitter } from 'grapheme-splitter'


export const isWordInWordList = (word: string) => {
  return (
    WORDS.includes(localeAwareLowerCase(word)) ||
    VALIDGUESSES.includes(localeAwareLowerCase(word))
  )
}

export const isWinningWord = (word: string) => {
  return solution === word
}

// build a set of previously revealed letters - present and correct
// guess must use correct letters in that space and any other revealed letters
// also check if all revealed instances of a letter are used (i.e. two C's)
export const findFirstUnusedReveal = (word: string, guesses: string[]) => {
  if (guesses.length === 0) {
    return false
  }

  const lettersLeftArray = new Array<string>()
  const guess = guesses[guesses.length - 1]
  const statuses = getGuessStatuses(guess)
  const splitWord = unicodeSplit(word)
  const splitGuess = unicodeSplit(guess)

  for (let i = 0; i < splitGuess.length; i++) {
    if (statuses[i] === 'correct' || statuses[i] === 'present') {
      lettersLeftArray.push(splitGuess[i])
    }
    if (statuses[i] === 'correct' && splitWord[i] !== splitGuess[i]) {
      return WRONG_SPOT_MESSAGE(splitGuess[i], i + 1)
    }
  }

  // check for the first unused letter, taking duplicate letters
  // into account - see issue #198
  let n
  for (const letter of splitWord) {
    n = lettersLeftArray.indexOf(letter)
    if (n !== -1) {
      lettersLeftArray.splice(n, 1)
    }
  }

  if (lettersLeftArray.length > 0) {
    return NOT_CONTAINED_MESSAGE(lettersLeftArray[0])
  }
  return false
}

export const unicodeSplit = (word: string) => {
  return new GraphemeSplitter().splitGraphemes(word)
}

export const unicodeLength = (word: string) => {
  return unicodeSplit(word).length
}

export const localeAwareLowerCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleLowerCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toLowerCase()
}

export const localeAwareUpperCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleUpperCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toUpperCase()
}

export const getWordOfDay = () => {
  // January 1, 2022 Game Epoch
  const epochMs = new Date('February 2, 2022 07:00:00').valueOf()
  const now = Date.now()
  //const msInDay = 86400000
  const msInDay = 28800000
  //const index = Math.floor((now - epochMs) / msInDay)
  //const index = 3
  const index = Math.floor(Math.random() * (140 - 1) + 1);
  //let ages = [1617400800000,1648980000000,1649001600000,1649023200000,1649066400000,1649088000000,1649109600000,1649152800000,1649174400000,1649196000000,1649239200000,1649260800000,1649282400000,1649325600000,1649347200000,1649368800000,1649412000000,1649419200000,1649426400000,1649433600000,1649440800000,1649448000000,1649455200000,1649462400000,1649498400000,1649505600000,1649512800000,1649520000000,1649527200000,1649534400000,1649541600000,1649548800000,1649584800000,1649592000000,1649599200000,1649606400000,1649613600000,1649620800000,1649628000000,1649635200000,1649671200000,1649678400000,1649685600000,1649692800000,1649700000000,1649707200000,1649714400000,1649721600000,1649757600000,1649764800000,1649772000000,1649779200000,1649786400000,1649793600000,1649800800000,1649808000000,1649844000000,1649851200000,1649858400000,1649865600000,1649872800000,1649880000000,1649887200000,1649894400000,1649930400000,1649937600000,1649944800000,1649952000000,1649959200000,1649966400000,1649973600000,1649980800000,1649988000000,1649995200000,1650002400000,1650009600000,1650016800000,1650024000000,1650031200000,1650038400000,1650045600000,1650052800000,1650060000000,1650067200000,1650103200000,1650110400000,1650117600000,1650124800000,1650132000000,1650139200000,1650146400000,1650153600000,1650189600000,1650196800000,1650204000000,1650211200000,1650218400000,1650225600000,1650276000000]
  //const index = ages.findIndex(age => age > now);
//  const index = 1

  const siguiente = index
  const nextday = (index + 1) * msInDay + epochMs
  //const nextday = ages[siguiente];
  //const nextday = ages[siguiente];
  return {
    solution: localeAwareUpperCase(WORDS[index % WORDS.length]),
    solutionIndex: index,
    ahora: now,
    tomorrow: nextday,
  }
}







export const { solution, solutionIndex, tomorrow, ahora } = getWordOfDay()


var arr = ["steve", "bob", "john"];

console.log(arr.indexOf("bob") > -1); //true



