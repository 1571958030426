export const WORDS = [
    'santa',
    'santa',
    'atril',
    'manos',
    'guion',
    'junta',
    'penas',
    'malla',
    'lanza',
    'aguao',
    'caida',
    'rampa',
    'palio',
    'velas',
    'palma',
    'marta',
    'sitio',
    'valle',
    'techo',
    'fajin',
    'pasos',
    'almas',
    'varal',
    'fagot',
    'cielo',
    'jarra',
    'tanda',
    'cirio',
    'codal',
    'palco',
    'silla',
    'maria',
    'dados',
    'venia',
    'niños',
    'sayon',
    'oliva',
    'bulla',
    'reyes',
    'roque',
    'rocio',
    'talla',
    'tramo',
    'varas',
    'lirio',
    'angel',
    'museo',
    'aguas',
    'banda',
    'cerro',
    'reina',
    'viaje',
    'burla',
    'buena',
    'malco',
    'dulce',
    'olivo',
    'plata',
    'jesus',
    'ojiva',
    'gubia',
    'viejo',
    'siete',
    'regla',
    'caliz',
    'poder',
    'zanco',
    'buiza',
    'atado',
    'armao',
    'señor',
    'atrio',
    'mayor',
    'dolor',
    'manto',
    'peana',
    'solea',
    'ojeda',
    'simon',
    'salud',
    'dimas',
    'gijon',
    'arria',
    'carey',
    'caoba',
    'puñal',
    'cinco',
    'mater',
    'magno',
    'duelo',
    'pablo',
    'saeta',
    'madre',
    'pasan',
    'farol',
    'sinai',
    'judas',
    'pulso',
    'monte',
    'palos',
    'altar',
    'cecop',
    'ramos',
    'bombo',
    'ariza',
    'ebano',
    'culto',
    'coral',
    'mateo',
    'andes',
    'libro',
    'liceo',
    'santo',
    'padua',
    'lunes',
    'barco',
    'andas',
    'perra',
    'gomez',
    'mujer',
    'calle',
    'padre',
    'dario',
    'plaza',
    'abril',
    'marzo',
    'perea',
    'roman',
    'yedra',
    'cedro',
    'ambon',
    'pedro',
    'cardo',
    'dosel',
    'ropon',
    'ancla',
    'burra',
    'soria',
    'calza',
    'coria',
]
