import Countdown from 'react-countdown'
import { tomorrow } from '../../lib/words'
import {
  RefreshIcon
} from '@heroicons/react/outline'
import {
  NEW_WORD_TEXT
} from '../../constants/strings'
export function Temporizador() {
  const refreshPage = ()=>{
    window.location.reload();
 }
{/*  if (isGameLost || isGameWon) {*/}
    return (
      <a href="" onClick={refreshPage}>
      <div className="mx-auto mt-2 text-center sm:mt-2 w-64 temporizador-contenedor">
     <RefreshIcon className="h-6 w-6 cursor-pointer dark:stroke-white temporizador mr-1"/>

      <h5 className='text-center'>Jugar a otra palabra</h5>
      <div className="mt-1 dark:text-white"> 

    </div></div></a>
    );
  {/*} else {
    return (null);
  }*/}
}

export function Temporizadornum() {

  {/*  if (isGameLost || isGameWon) {*/}
      return (
              <div className="mt-[-5px] dark:text-white temporizador mr-1 text-center">
  

          <Countdown
                className="text-lg font-medium text-gray-900 dark:text-gray-100"
                date={tomorrow}
                daysInHours={true}
               onComplete={ ()=>{
                window.location.reload();
             }}
               
                />
  
      </div>
      );
    {/*} else {
      return (null);
    }*/}
  }