import {URL_BLOG, ACIERTO_TEXTO_BLOG, HAY_BLOG, HAY_BANNER_PUBLI_EN_MODAL,PUBLI_MODAL_ACIERTO_URL,PUBLI_MODAL_ACIERTO_TEXTO
  } from '../../constants/strings'
import {Temporizador} from './Temporizador'

export function BannerAciertoBlog() {
{/*  if (HAY_BLOG) {
    return (
      <div className="mx-auto mt-3 text-center sm:mt-5 modal-titulo"><a href={URL_BLOG} target='_blank' rel='noreferrer'>
      <button type="button" className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-100 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none sm:text-sm boton-blog-modal"
>{ACIERTO_TEXTO_BLOG}</button></a></div>
    );
  } else {
    return (null);
  }*/}
  return (
    <Temporizador></Temporizador>
  );
}

  export function BannerAciertoPubli() {

    if (HAY_BANNER_PUBLI_EN_MODAL) {
      return (
        <div className="mx-auto mt-3 text-center sm:mt-5 modal-titulo"><a href={PUBLI_MODAL_ACIERTO_URL} target='_blank' rel='noreferrer'>
        <button type="button" className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-indigo-700 focus:outline-none sm:text-sm boton-publi-modal"
  >{PUBLI_MODAL_ACIERTO_TEXTO}</button></a></div>
      );
    } else {
      return (null);
    }
}