import { getGuessStatuses } from './statuses'
import { solutionIndex, unicodeSplit } from './words'
import { WORDLE_TITLE,HASHTAG, URL,EMOJI_OK, EMOJI_PRESENT,EMOJI_ABSENT} from '../constants/strings'

export const shareStatus = (
  guesses: string[],
  lost: boolean,
  isHardMode: boolean,
  isDarkMode: boolean,
  isHighContrastMode: boolean,
  handleShareToClipboard: () => void,
  
  ) => {
    const textToShare =
    `${WORDLE_TITLE} - #${solutionIndex} ${lost ? 'X' : guesses.length}/6\n\n` +
    generateEmojiGrid(guesses, getEmojiTiles(isDarkMode, isHighContrastMode))+
    '\n\n'+
    `${HASHTAG}` +
    '\n\n'+
    `${URL}`
 
    navigator.clipboard.writeText(textToShare)
    handleShareToClipboard()
 
}

export const generateEmojiGrid = (guesses: string[], tiles: string[]) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess)
      const splitGuess = unicodeSplit(guess)

      return splitGuess
        .map((_, i) => {
          switch (status[i]) {
            case 'correct':
              return tiles[0]
            case 'present':
              return tiles[1]
            default:
              return tiles[2]
          }
        })
        .join('')
    })
    .join('\n')
}


const getEmojiTiles = (isDarkMode: boolean, isHighContrastMode: boolean) => {
  let tiles: string[] = []
  tiles.push(isHighContrastMode ? `${EMOJI_OK}` : `${EMOJI_OK}`)
  tiles.push(isHighContrastMode ? `${EMOJI_PRESENT}` : `${EMOJI_PRESENT}`)
  tiles.push(isDarkMode ? `${EMOJI_ABSENT}` : `${EMOJI_ABSENT}`)
  return tiles
}

export const TEXTOCOMPARTIR = (WORDLE_TITLE: string) =>
  `La palabra era ${WORDLE_TITLE}. Mañana tendrás una nueva palabra`

  