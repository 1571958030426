import {URL_BLOG, REVELAMOS_SOLUCION,FALLO_TEXTO_BLOG2,HAY_BLOG,FALLO_TEXTO_BLOG1,HAY_BANNER_PUBLI_EN_MODAL,PUBLI_MODAL_ACIERTO_TEXTO,PUBLI_MODAL_ACIERTO_URL
} from '../../constants/strings'
import { solution } from '../../lib/words'
import {Temporizador} from './Temporizador'


export function BannerFalloBlog() {
 {/*  if (HAY_BLOG) {
  return (
    <div className="mx-auto mt-3 text-center sm:mt-5 modal-titulo">
<a href={URL_BLOG} target='_blank' rel='noreferrer'><button type="button" className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-100 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none sm:text-sm"
>{FALLO_TEXTO_BLOG1}<br></br>{FALLO_TEXTO_BLOG2}</button></a>

</div>
    );
  } else {
    return (null);
  }*/}
  return (
    <Temporizador></Temporizador>
  );
}
export function BannerFalloPubli() {

  if (HAY_BANNER_PUBLI_EN_MODAL) {
    return (
      <div className="mx-auto mt-3 text-center sm:mt-5 modal-titulo"><a href={PUBLI_MODAL_ACIERTO_URL} target='_blank' rel='noreferrer'>
      <button type="button" className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-indigo-700 focus:outline-none sm:text-sm boton-publi-modal"
>{PUBLI_MODAL_ACIERTO_TEXTO}</button></a></div>
    );
  } else {
    return (null);
  }
}
export function BannerFalloSolucion() {
  if (REVELAMOS_SOLUCION) {
  return (
    <p className="text-base text-gray-900 font-medium text-center">La palabra era {solution}<br></br>
    Mañana a las 7am tendrás una nueva palabra para jugar.</p>
    );
  } else {
    return (null);
  }
}