import { __rest } from "tslib";
import React, { useEffect } from 'react';
export const Adsense = (_a) => {
    var { className = '', style = { display: 'block' }, client, slot, layout = '', layoutKey = '', format = 'auto', responsive = 'false', pageLevelAds = false, adTest } = _a, rest = __rest(_a, ["className", "style", "client", "slot", "layout", "layoutKey", "format", "responsive", "pageLevelAds", "adTest"]);
    useEffect(() => {
        const p = {};
        if (pageLevelAds) {
            p.google_ad_client = client;
            p.enable_page_level_ads = true;
        }
        try {
            if (typeof window === 'object') {
                (window.adsbygoogle = window.adsbygoogle || []).push(p);
            }
        }
        catch (_a) {
            // Pass
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement("ins", Object.assign({ className: `adsbygoogle ${className}`, style: style, "data-ad-client": client, "data-ad-slot": slot, "data-ad-layout": layout, "data-ad-layout-key": layoutKey, "data-ad-format": format, "data-full-width-responsive": responsive, "data-adtest": adTest }, rest)));
};
