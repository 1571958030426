import { BaseModal } from './BaseModal'
import { SettingsToggle } from './SettingsToggle'
import {/*HARD_MODE_DESCRIPTION,*/SETTINGS_MODONOCHE_TEXTO, 
SETTINGS_MODODALTONICOS_TEXTO, SETTINGS_MODODALTONICOS_DES, URL_BLOG, BOTONSOLUCIONES_TEXTO,CONTACTO_TEXTO_ANTES,CONTACTO_TEXTO_TXT, URL_CONTACTO, AUTOR_TEXTO_PREVIO, AUTOR_TEXTO_AUTOR, AUTOR_TEXTO_URL

} from '../../constants/strings'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isDarkMode: boolean
  handleDarkMode: Function
  isHighContrastMode: boolean
  handleHighContrastMode: Function
}

export const SettingsModal = ({
  isOpen,
  handleClose,
  isDarkMode,
  handleDarkMode,
  isHighContrastMode,
  handleHighContrastMode,
}: Props) => {
  return (
    <BaseModal title="Ajustes" isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-col mt-2 divide-y">
        <SettingsToggle
          settingName={SETTINGS_MODONOCHE_TEXTO}
          flag={isDarkMode}
          handleFlag={handleDarkMode}
        />
        <SettingsToggle
          settingName={SETTINGS_MODODALTONICOS_TEXTO}
          flag={isHighContrastMode}
          handleFlag={handleHighContrastMode}
          description={SETTINGS_MODODALTONICOS_DES}
        />
    
      </div>
    </BaseModal>
  )
}
