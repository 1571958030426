import React from 'react'
import Countdown from 'react-countdown'
import {
  RefreshIcon
} from '@heroicons/react/outline'
import { tomorrow } from '../../lib/words'
import {
  NEW_WORD_TEXT,
} from '../../constants/strings'
type Props = {
   isGameLost: boolean
  isGameWon: boolean
}
const refreshPage = ()=>{
  window.location.reload();
}
export const Temporizador2 = ({
  isGameLost,
  isGameWon,
}: Props) => {
  return (
    <div>
    
      {(isGameLost || isGameWon) && (
      
      
      <a href="" onClick={refreshPage}>
      <div className="mx-auto mt-2 text-center sm:mt-2 w-64 temporizador-contenedor">
     <RefreshIcon className="h-6 w-6 cursor-pointer dark:stroke-white temporizador mr-1"/>

      <h5 className='text-center'>Jugar a otra palabra</h5>
      <div className="mt-1 dark:text-white"> 

    </div></div></a>
      )}
 </div>
  )
}