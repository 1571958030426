import { FullModal } from './FullModal'
import {CONTACTO_TEXTO_ANTES,CONTACTO_TEXTO_TXT, URL_CONTACTO, AUTOR_TEXTO_PREVIO, AUTOR_TEXTO_AUTOR, AUTOR_TEXTO_URL
} from '../../constants/strings'
import { useState } from 'react'
import { InfoModal } from '../../components/modals/InfoModal'
import { IframePage,IframePageHorarioPalabras, IframePageTV, IframePagePrograma, IframePageUltimahora } from '../../components/modals/Iframepage'
import {Temporizador} from '../../components/banners/Temporizador'
import { SettingsModal } from '../../components/modals/SettingsModal'

import {
  PlayIcon,InformationCircleIcon,CogIcon} from '@heroicons/react/outline'
type Props = {
  isOpen: boolean
  handleClose: () => void
  isHardMode: boolean
  handleHardMode: Function
  isDarkMode: boolean
  handleDarkMode: Function
  isHighContrastMode: boolean
  handleHighContrastMode: Function
}
export const MenuModal = ({
  isOpen,
  handleClose,
  isDarkMode,
  handleDarkMode,
  isHighContrastMode,
  handleHighContrastMode,
}: Props) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isIframePageOpen, setIsIframepageOpen] = useState(false)
  const [isIframePageHorarioPalabrasOpen, setIsIframePageHorarioPalabrasOpen] = useState(false)
  const [isIframePageProgramaOpen, setIsIframePageProgramaOpen] = useState(false)
  const [isIframePageTVOpen, setIsIframePageTVOpen] = useState(false)
  const [isIframePageUltimahoraOpen, setIsIframePageUltimahoraOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)

  return (
    
    <FullModal title="COFRADE WORDLE MENÚ" isOpen={isOpen} handleClose={handleClose}>

<div className="menu flex flex-col grow">
  <div className="grow" >
<ul className="accordion-menu dark:bg-gray-800">
  <li>
  <a href="#" onClick={() => setIsInfoModalOpen(true)}><div className="dropdownlink py-8">
  <InformationCircleIcon
          className="h-6 w-6 cursor-pointer dark:stroke-white"></InformationCircleIcon><span className="menu-texto dark:text-white">Cómo jugar</span>
    </div></a>
  </li>
 {/* <li>
  <a href="#" onClick={() => setIsInfoModalOpen(true)}><div className="dropdownlink"><i className="fa fa-road" aria-hidden="true"></i>
  <ChartBarIcon
          className="h-6 w-6 cursor-pointer dark:stroke-white"></ChartBarIcon><span className="menu-texto">Estadísticas</span>
      <i className="fa fa-chevron-down" aria-hidden="true"></i>
    </div></a>
  </li>*/}
  <li>
  <a href="#" onClick={() => setIsSettingsModalOpen(true)}><div className="dropdownlink py-8">
  <CogIcon
          className="h-6 w-6 cursor-pointer dark:stroke-white"></CogIcon><span className="menu-texto dark:text-white">Ajustes</span>
    </div></a>
  </li>
  <li>
  <div className="dropdownlink dropdownlink2 py-2 text-center">
<Temporizador
          ></Temporizador>
    </div>
  </li>

</ul>
</div>


<div className="grow" >
<ul className="accordion-menu dark:bg-gray-800">
  <li>
  <a href="https://wordle.angelvilches.com/" target="_blank noreferrer"><div className="dropdownlink">
  <PlayIcon
          className="h-6 w-6 cursor-pointer dark:stroke-white"></PlayIcon><span className="menu-texto dark:text-white">Sevilla Wordle</span>
    </div></a>
  </li>
  <li>
  <a href="https://wordleand.angelvilches.com/" target="_blank noreferrer"><div className="dropdownlink">
  <PlayIcon
          className="h-6 w-6 cursor-pointer dark:stroke-white"></PlayIcon><span className="menu-texto dark:text-white">Andalucía Wordle</span>
    </div></a>
  </li>
 {/* <li>
  <a href="#" onClick={() => setIsIframepageOpen(true)}><div className="dropdownlink">
  <MicrophoneIcon
          className="h-6 w-6 cursor-pointer dark:stroke-white"></MicrophoneIcon><span className="menu-texto">Radios cofrades directo</span>
    </div></a>
</li> */}
</ul>
</div>


    
          <div className="grow">


        <div className='justify-center gap-4 py-3'>
        <p className="text-sm text-white dark:text-gray-300">
        {CONTACTO_TEXTO_ANTES} {' '}
        <a
          href={URL_CONTACTO}
          target='_blank'
          rel='noreferrer'
          className="underline font-bold"
        >{CONTACTO_TEXTO_TXT}</a>.
      </p>
      <p className="text-xs text-white dark:text-gray-300">
        {AUTOR_TEXTO_PREVIO}  {' '}
        <a
          href={AUTOR_TEXTO_URL} 
          target='_blank'
          rel='noreferrer'
          className="underline font-bold"
        >{AUTOR_TEXTO_AUTOR}</a>.
      </p></div>
      
      </div>
      <SettingsModal
          isOpen={isSettingsModalOpen}
          handleClose={() => setIsSettingsModalOpen(false)}
          isDarkMode={isDarkMode}
          handleDarkMode={handleDarkMode}
          isHighContrastMode={isHighContrastMode}
          handleHighContrastMode={handleHighContrastMode}
        />
                      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <IframePage
        isOpen={isIframePageOpen}
        handleClose={() => setIsIframepageOpen(false)}
      />
      <IframePageHorarioPalabras
        isOpen={isIframePageHorarioPalabrasOpen}
        handleClose={() => setIsIframePageHorarioPalabrasOpen(false)}
      />
        <IframePagePrograma
        isOpen={isIframePageProgramaOpen}
        handleClose={() => setIsIframePageProgramaOpen(false)}
      />
       <IframePageTV
        isOpen={isIframePageTVOpen}
        handleClose={() => setIsIframePageTVOpen(false)}
      />
             <IframePageUltimahora
        isOpen={isIframePageUltimahoraOpen}
        handleClose={() => setIsIframePageUltimahoraOpen(false)}
      />
      </div>
    </FullModal>
    
  )
}

