import { INFO_DESCRIPCION1, INFO_DESCRIPCION2,URL_CONTACTO,INFO_PRIVACIDAD_ANTES, INFO_PRIVACIDAD_PRIVA,INFO_PRIVACIDAD_URL,CONTACTO_TEXTO_ANTES,CONTACTO_TEXTO_TXT  } from '../../constants/strings'
import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="¿Cómo se juega?" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300 margen-inferior font-medium">
      MODO ALEATORIO: Recarga la página y juega por una nueva palabra. Sin límite entre las 139 palabras que tenemos.
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300 margen-inferior">
      Adivina la palabra en 6 intentos. Después de cada intento, el color de las letras cambiará.
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300 ">
      Siempre será una palabra relacionada con la Semana Santa de Sevilla: Hermandades, imágenes, partes de un paso, artesanos, marchas, bandas...
      </p>



      <div className="flex justify-center mb-1 mt-4">
      <Cell isRevealing={true} isCompleted={true} value="B" status="correct"/>
        <Cell value="A" />
        <Cell value="N" />
        <Cell value="D" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        La letra <strong>B</strong> forma parte de la palabra y está en su posición correcta.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="A" />
        <Cell value="R" />
        <Cell isRevealing={true} isCompleted={true} value="M" status="present"/>
        <Cell value="A" />
        <Cell value="O" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        La letra <strong>M</strong> forma parte de la palabra, pero no está en la posición correcta.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="P" />
        <Cell value="A" />
        <Cell value="L" />
        <Cell isRevealing={true} isCompleted={true} value="I" status="absent"/>
        <Cell value="O" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300 margen-inferior">
        La letra <strong>I</strong> no está en la palabra. 
      </p>
  {/*    <p className="text-sm text-gray-500 dark:text-gray-300">
      {CONTACTO_TEXTO_ANTES} {' '}
        <a
          href={URL_CONTACTO}
          target='_blank'
          rel='noreferrer'
          className="underline font-bold"
        >{CONTACTO_TEXTO_TXT}</a>.</p>*/}
      <p className="text-xs text-gray-500 dark:text-gray-300 mt-4">
      {INFO_PRIVACIDAD_ANTES} {' '}
        <a
          href={INFO_PRIVACIDAD_URL}
          target='_blank'
          rel='noreferrer'
          className="underline font-bold"
        >{INFO_PRIVACIDAD_PRIVA}</a>.</p>
            </BaseModal>
  )
}
