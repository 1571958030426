import { INFO_DESCRIPCION1, INFO_DESCRIPCION2,URL_CONTACTO,INFO_PRIVACIDAD_ANTES, INFO_PRIVACIDAD_PRIVA,INFO_PRIVACIDAD_URL,CONTACTO_TEXTO_ANTES,CONTACTO_TEXTO_TXT  } from '../../constants/strings'
import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const IframePage = ({ isOpen, handleClose }: Props) => {
  return (
      
<BaseModal title="El Tiempo" isOpen={isOpen} handleClose={handleClose}>
<img src="https://www.theweather.com/wimages/fotoa001481222e13e3279fc476ddf78cbfa.png" className="mx-auto"></img></BaseModal>
  )
}

export const IframePageHorarioPalabras = ({ isOpen, handleClose }: Props) => {
  return (
      
<BaseModal title="Horario nuevas palabras" isOpen={isOpen} handleClose={handleClose}>
<img src="https://wordleco.angelvilches.com/template/horarios-palabras.png" className="mx-auto"></img></BaseModal>
  )
}

export const IframePageTV = ({ isOpen, handleClose }: Props) => {
  return (
      
<BaseModal title="" isOpen={isOpen} handleClose={handleClose}>
<div className="iframe-container">
<iframe src="https://iteleflix.com/ver/semana-santa-sevilla-en-directo-online/" scrolling="yes" allowFullScreen>This browser does not support Iframe</iframe>
</div></BaseModal>
  )
}

export const IframePagePrograma = ({ isOpen, handleClose }: Props) => {
  return (
      
<BaseModal title="Programa de mano" isOpen={isOpen} handleClose={handleClose}>
<div className="h-screen w-full">
<iframe src="https://wordle.angelvilches.com/sevilla/horarios-e-itinerarios/" name="myiFrame" scrolling="yes" frameBorder="0" height="400px" width="300px" allowFullScreen></iframe>

</div></BaseModal>
  )
}

export const IframePageUltimahora = ({ isOpen, handleClose }: Props) => {
  return (
      
<BaseModal title="Última hora CECOP" isOpen={isOpen} handleClose={handleClose}>
<div className="h-screen w-full">
<iframe src="https://wordle.angelvilches.com/sevilla/twitter-feed/" name="myiFrame" scrolling="yes" frameBorder="0" height="400px" width="300px" allowFullScreen></iframe>

</div>
  </BaseModal>
  )
}


