export const WORDLE_TITLE = 'Cofrade Wordle'
export const ABOUT_GAME_MESSAGE = 'Sobre Cofrade Wordle'

export const HAS_GANADO = 'Has acertado'
export const WIN_MESSAGES = ['¡Al Cielo con ella!','¡Ole la gente buena!','¿Tú desayunas agua bendita?','Vas para Hermano/a Mayor','¡Ole las cuadrillas que se atreven con los kilos!','Lo tuyo es de jartible total.','¿Eres de esas personas que en verano escuchan marchas, no?','A la Gloria, sevillan@s, a la Gloria ','¡La vida es una semana!','Te fumas el incienso. ¡Qué arte!','Tienes nivel de concurso de cultura cofrade','Van a quitar al Pilato para ponerte a tí.','Esto hay que celebrarlo con unas torrijas','Quita al capataz y ponte tu','Vas para pregonero/a de la Semana Santa.','Mereces un sahumerio']
export const HAS_FALLADO = 'Has fallado'
export const LOST_MESSAGES = ['¿Te sueles ir a la playa en Semana Santa?','Lo importante es el patrimonio humano','Vale por una semana en Matalascañas durante Semana Santa','Tus Dolores son mis Penas','Réquiem','Pararse ahí! Salirse.','Normal que falles, dos años sin pasos es demasiado. ','¿Cuántas veces te pierdes cada Semana Santa?','Eres más de bares que de pasos','Te van a dar un ciriazo con un buen chorreón','Está chispeando por el Aljarafe','Eres más malo/a que una valla en Semana Santa','No vamos a hacer de esto una tragedia']

export const HASHTAG = '#CofradeWordle #SSantaSevilla22'
export const URL = 'https://wordleco.angelvilches.com'
export const EMOJI_OK = '🟢'
export const EMOJI_PRESENT = '🟡'
export const EMOJI_ABSENT = '⚪'

export const HAY_BLOG = false
export const HAY_BANNER_PUBLI_EN_MODAL = false

export const REVELAMOS_SOLUCION = false
export const BANNER_ACIERTO = 1
export const BANNER_FALLO = 1
export const PUBLI_MODAL_ACIERTO_URL = ''
export const PUBLI_MODAL_ACIERTO_TEXTO = ''
export const HAY_ADSENSE = true
export const ADSENSE_CA = "ca-pub-1474100513598316"
export const ADSENSE_BANN_TECLADO = "1132028358"



export const AUTOR_TEXTO_PREVIO = 'Juego adaptado por'
export const AUTOR_TEXTO_AUTOR = 'Ángel Vilches'
export const AUTOR_TEXTO_URL = "https://angelvilches.com"


export const INFO_DESCRIPCION1 = "Adivina la palabra en 6 intentos. Después de cada intento, el color de las letras cambiará para indicarte si estás cerca o lejos de la solución."
export const INFO_DESCRIPCION2 = "Hermandades, imágenes, partes de un paso, artesanos, marchas, bandas..."
export const INFO_PRIVACIDAD_ANTES = "Al jugar estás aceptando la"
export const INFO_PRIVACIDAD_PRIVA = "política de privacidad"
export const INFO_PRIVACIDAD_URL = "https://wordle.angelvilches.com/sevilla/politica-privacidad/"


export const URL_CONTACTO = "https://wordle.angelvilches.com/sevilla/contacto"
export const URL_BLOG = "https://wordle.angelvilches.com/sevilla"
export const ACIERTO_TEXTO_BLOG = "+ info sobre la palabra de cada día en el BLOG"
export const FALLO_TEXTO_BLOG1 = "A partir del día 18 de abril puedes jugar otra vez para adivinar esta misma palabra"
export const FALLO_TEXTO_BLOG2 = ""


export const CONTACTO_TEXTO_ANTES = "Puedes contactar a través de"
export const CONTACTO_TEXTO_TXT = "este formulario"

export const SETTINGS_MODONOCHE_TEXTO = "Modo noche"
export const SETTINGS_MODONOCHE_DES = "Modo noche"
export const SETTINGS_MODODALTONICOS_TEXTO = "Modo daltónicos"
export const SETTINGS_MODODALTONICOS_DES = "Cambia los colores de las letras para ayudar a personas con esta dificultad"

export const BOTONSOLUCIONES_TEXTO = "SOLUCIONES"

export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Must use ${guess} in position ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Guess must contain ${letter}`
  export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `La palabra era ${solution}. Mañana tendrás una nueva palabra`
  export const GAME_COPIED_MESSAGE = 'Juego copiado al portapapeles'
export const STATISTICS_TITLE = 'Estadísticas'
export const GUESS_DISTRIBUTION_TEXT = 'Distribución'
export const NEW_WORD_TEXT = 'Nueva palabra en '
export const SHARE_TEXT = 'COMPARTIR'
export const TOTAL_TRIES_TEXT = 'Intentos totales'
export const SUCCESS_RATE_TEXT = 'Porcentaje de aciertos'
export const CURRENT_STREAK_TEXT = 'Racha actual'
export const BEST_STREAK_TEXT = 'Mejor racha'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Te faltan letras, deben ser 5.'
export const WORD_NOT_FOUND_MESSAGE = 'Palabra no encontrada en nuestro diccionario'
export const ENTER_TEXT = 'Intro'
export const DELETE_TEXT = 'Borrar'
export const HARD_MODE_ALERT_MESSAGE =
  'Hard Mode can only be enabled at the start!'
export const HARD_MODE_DESCRIPTION =
  'Any revealed hints must be used in subsequent guesses'